var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('c-flex',{attrs:{"justifyContent":"center","align":"center"}},[(_vm.currentPage > 3)?_c('c-button',{attrs:{"py":"0","px":"14px","mr":"8px","h":"32px","min-w":"32px","variant":"outline","borderRadius":"30px","backgroundColor":"transparent","font-size":"14px","font-weight":"normal"},on:{"click":_vm.onClickFirstPage}},[_vm._v(" First ")]):_vm._e(),_c('c-button',{attrs:{"p":"0","mr":"16px","w":"32px","h":"32px","min-w":"32px","variant":"outline","borderRadius":"30px","backgroundColor":"transparent","disabled":_vm.isInFirstPage},on:{"click":_vm.onClickPreviousPage}},[_c('c-icon',{attrs:{"name":"chevron-left","size":"24px"}})],1),_vm._l((_vm.pages),function(page){return _c('c-button',{directives:[{name:"chakra",rawName:"v-chakra",value:({
      ':hover': {
        bg: 'rgba(0, 140, 129, 0.3)',
        color: 'brand.900',
      },
    }),expression:"{\n      ':hover': {\n        bg: 'rgba(0, 140, 129, 0.3)',\n        color: 'brand.900',\n      },\n    }"}],key:page,class:{
      last: page == _vm.totalPages && Math.abs(page - _vm.currentPage) > 2,
    },attrs:{"p":"0","m":"0","w":"32px","h":"32px","min-w":"32px","mx":"4px","borderRadius":"30px","font-weight":"normal","backgroundColor":_vm.isPageActive(page) ? 'rgba(0, 140, 129, 0.3)' : 'transparent',"color":"brand.900"},on:{"click":function($event){return _vm.onClickPage(page)}}},[_vm._v(" "+_vm._s(page)+" ")])}),_c('c-button',{attrs:{"p":"0","ml":"16px","w":"32px","h":"32px","min-w":"32px","variant":"outline","borderRadius":"30px","backgroundColor":"transparent","disabled":_vm.isInLastPage},on:{"click":_vm.onClickNextPage}},[_c('c-icon',{attrs:{"name":"chevron-right","size":"24px"}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }